import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Pass`}</h1>
    <p>{`A kind of `}<a parentName="p" {...{
        "href": "/vote",
        "title": "vote"
      }}>{`vote`}</a>{` cast by a `}<a parentName="p" {...{
        "href": "/participant",
        "title": "participant"
      }}>{`participant`}</a>{` in a `}<a parentName="p" {...{
        "href": "/conversation",
        "title": "conversation"
      }}>{`conversation`}</a>{` which signals that they did not `}<a parentName="p" {...{
        "href": "/agree",
        "title": "agree"
      }}>{`agree`}</a>{` or `}<a parentName="p" {...{
        "href": "/disagree",
        "title": "disagree"
      }}>{`disagree`}</a>{`.
This signal contributes to the section of the `}<a parentName="p" {...{
        "href": "/report",
        "title": "report"
      }}>{`report`}</a>{` dealing with `}<a parentName="p" {...{
        "href": "/areas-of-uncertainty",
        "title": "areas of uncertainty"
      }}>{`areas of uncertainty`}</a>{`. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      